<template>
  <div :class="[productList.length == 0 ? 'noItems' : '', 'drawableInfoList']">
    <ul class="list">
      <li
        class="Itme"
        v-for="(item, index) in productList"
        :key="index"
        @click="getProductDetails(item.ProductNumber)"
      >
        <div class="title">
          <div class="discount" v-if="item.IsCouponProduct">
            {{ $t.discount }}
          </div>
          <div class="brand" v-if="item.IsBrandProduct">
            {{ $t.brand }}
          </div>
          <div class="attestation" v-if="item.IsAttestationProduct">
            {{ $t.attestation }}
          </div>
          <div class="name">{{ item.ProductName }}</div>
        </div>
        <!-- 图片 -->
        <ul class="imgLIst">
          <li
            class="imgItem"
            v-for="(imgItem, newIndex) in item.ProductPhoto.split('|')"
            :key="newIndex"
          >
            <!-- <img class="imgItemIcon" :src="imgItem && imgUrlFilter(imgItem)" /> -->

            <van-image class="logoIcon" :src="imgItem && imgUrlFilter(imgItem)">
              <template v-slot:error>
                <img class="ImgIcon" src="~assets/img/other/empty.gif" />
              </template>
            </van-image>
          </li>
        </ul>
        <van-row type="flex" align="center" class="priceBox">
          <van-col :span="1" class="unit">$</van-col>
          <!-- <van-col
            :span="14"
            class="num"
            @click="getProductDetails(item.ProductNumber)"
          >
            {{ item.CurrentPrice }}
          </van-col>-->
          <van-col :span="14" class="num">{{
            Math.round(item.MinPrice * 100) / 100
          }}</van-col>
          <van-col
            :span="9"
            class="share"
            @click.stop="
              shareGoods(
                item.ProductNumber,
                item.ProductName,
                item.MinPrice,
                item.ProductPhotoList[0]
              )
            "
          >
            <button class="shareBt">
              <span class="text">{{ $t.forward }}</span>
              <van-icon name="arrow" size="20" />
            </button>
          </van-col>
        </van-row>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from "vue";
import { Image, Icon } from "vant";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);
Vue.use(Image);
Vue.use(Icon);

export default {
  name: "DrawableInfoList",
  props: ["productList"],
  components: {},
  data() {
    return {};
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    //跳转详情页
    getProductDetails(ProductNumber) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
        ],
      });
    },
    //转发方法
    shareGoods(id, name, price, image) {
      let auth = JSON.parse(localStorage.getItem("auth"));
      this.$commonEnv.commonAction(this.$commonEnv.actionType.shareGoods, {
        id: id,
        name: name,
        price: price,
        image: image,
        userId: auth.userId,
        shopId: auth.shopId,
        shareUrl:
          "https://shop.vancheen.com/web/details?ProductNumber=" +
          auth.shopId +
          id +
          "&ShopId=" +
          auth.shopId +
          "&BackHome=true",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
@mixin publicBt {
  cursor: pointer;
  -webkit-cursor: pointer;
  border-radius: 32px;
  padding: 12px 0;
  text-align: center;
}
.noItems {
  margin-top: 0px !important;
}
.drawableInfoList {
  width: 92%;
  margin: 0 auto;
  position: relative;
  margin-top: -15px;
  .list {
    list-style: none;
    margin: 0;
    padding: 0;
    .Itme {
      margin: 0;
      margin-bottom: 15px;
      list-style: none;
      background-color: #ffffff;
      border-radius: 26px;
      box-shadow: 0 0 0px 1px #ece7e7;
      padding: 20px;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      .title {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 14px;
        color: #000000;
        .discount {
          vertical-align: middle;
          display: inline-block;
          background: red;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          margin-right: 5px;
          font-size: 10px;
        }
        .brand {
          vertical-align: middle;
          display: inline-block;
          background: #e6a23c;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 10px;
          margin-right: 5px;
        }
        .attestation {
          vertical-align: middle;
          display: inline-block;
          background: #22b196;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 10px;
          margin-right: 5px;
        }
        .name {
          vertical-align: middle;
          display: inline-block;
        }
      }
      .imgLIst {
        margin: 0;
        padding: 0;
        margin-top: 20px;
        list-style: none;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        @include puiblicFlex;
        &::-webkit-scrollbar {
          display: none;
          width: 0px;
        }
        .imgItem {
          margin-right: 10px;
          .logoIcon {
            width: 26.67vw;
            height: 26.67vw;
            object-fit: cover;
            -webkit-object-fit: cover;
            .ImgIcon {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            ::v-deep .van-image__img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            ::v-deep .van-image__error {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
      .priceBox {
        color: #ff5852;
        margin-top: 16px;
        .unit {
          font-size: 18px;
        }
        .num {
          font-size: 20px;
          font-weight: bold;
        }
        .share {
          text-align: right;
          line-height: 20px;
          .shareBt {
            border: none;
            color: #25b9ff;
            background-color: #ffffff;
            text-align: center;
            font-size: 16px;

            .van-icon {
              margin-left: 5px;
              vertical-align: middle;
            }
            .text {
              vertical-align: middle;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
